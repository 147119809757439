<template >
  <div :style="getStyle" class="data-table-view">
    <el-row align="middle">
      <el-col :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24">
        <label for="text">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
         <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span
          class="setting-icon"
          @click="openSettings"
        >
          <i class="el-icon-s-tools" />
        </span>
      </el-col>
    </el-row>
    <div v-if="!data.card_view" class="table-container" :style="getContainerStyle">
        <div style="width: 100%;" class="fixed-table">
          <div>
            <span v-if="!data.data_table_columns || !data.data_table_columns.length">
              <div class="instruction-text"> <i>Build & configure this division by dragging fields here..</i></div>
            </span>
          </div>
          <div class="division-view">
            <template v-if="data.data_table_columns && data.data_table_columns.length">
              <div
                v-for="(column, index) in data.data_table_columns"
                :key="index"
                :style="`width:${column.width}px;`"
              >
                <span @click="(e) => checkInner(e, column, index)">
                  <component
                    v-bind:is="column.components.view"
                    :data="column"
                    :isDataTableField="true"
                    v-on:settings="openSettingsForDataTable($event,column,index)"
                  ></component>
                </span>
              </div>
            </template>
          </div>
        </div>
    </div>
    <div v-if="data.card_view" class="table-container">
      <el-scrollbar class="scrollbar">
        <div style="width: 100%;" class="fixed-table">
          <div style="display: flex; justify-content: space-evenly; flex-wrap: wrap; border: 1px dotted grey; min-height: 100px;">
            <div v-if="!data.data_table_columns || !data.data_table_columns.length">
              <span> <i>configure table fields by draging into table</i></span>
            </div>
            <div v-else class="single-card">
              <div
                v-for="(column, index) in data.data_table_columns"
                :key="index"
                :style="{ width: column.width + 'px' }"
              >
                <span @click="(e) => checkInner(e, column, index)">
                  <component
                    v-bind:is="column.components.view"
                    :data="column"
                    :isDataTableField="true"
                    v-on:settings="openSettingsForDataTable($event,column,index)"
                  ></component>
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { AllowedDivisionViewFields } from "./AllowedDivisionViewFields";
import { mapGetters } from "vuex";
export default {
  name: "DivisionView",
  props: ["field", "data", "isActive", "fieldsData","isDataTableField"],
  components: {
    ...AllowedDivisionViewFields
  },
  data() {
    return {
      showLabel: false,
      aggregateFunctionsBasedOnTable: []
    };
  },
  mounted() {
    this.data.showLabel = false;
    this.aggregateFunctionsBasedOnTable = this.fieldsData.filter(field => {
      if (field.fields_type == "TABLE_FIELDS") {
        let selectedField = field.selected_fields.find(e => {
          let index = this.data.data_table_columns.findIndex(
            field => field.key == e.key
          );

          if (index > -1) {
            // this.data.data_table_columns[index].aggregate_function = field;
            return true;
          }

          return false;
        });
        return selectedField ? true : false;
      }
    });
    

    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }
      return (
        inputStyle  +
        this.getElementStyle
      );
    },
    getContainerStyle(){
      return `height:${this.data.height - 20}px;overflow:auto;scrolbar-width:thin`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    }
  },
  methods: {
    checkInner(e, column, index) {
      if (!e) e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$emit("configureColumn", { column, index, tableIndex: 0 });
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "HTML_CONTENT") {
          // this.isList = true;
          this.data.content = this.getGlobalVariableById.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
    // tableWith()
    // {
    //   if()
    //   {
    //     return 'min-width:500px;'
    //   }
    //   else{
    //     return 'width:100%;'
    //   }
    // },
    openSettingsForDataTable(e, column, index) {
      if (!e) e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$emit("settingsDataTableColumn", { column, index, tableIndex: 0 });
    }
  }
};
</script>

<style lang="scss" scoped>
.resposive-font {
  font-size: 1.2vw;
}
</style>

<style scoped>
table tr {
  vertical-align: top;
}

table,
th,
td {
  border: 1px solid var(--lighter-primary-color);
  background-color: white;
  width: 100px;
  height: 50px;
  line-height: normal;
}

tr.noBorder td th {
  border: 0;
}
</style>

<style scoped>
.data-table-view{
  /* max-width: 1180px; */
}
.table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto; /* Add a horizontal scrollbar if content overflows */
  scrollbar-width: thin;
  border-radius: 15px;
  box-shadow: 0 0px 10px 3px rgba(27, 72, 126, 0.1294117647);
}

.scrollbar {
  /* Set a fixed width for the scrollbar container */
  width: 100%;
}

.fixed-table {
  width: 100%; /* Make the table take up the full width of the container */
  table-layout: fixed; /* Fix the table layout */
  border-collapse: collapse; /* Collapse table borders */
}

/* Define a fixed width for each column */
.fixed-table th,
.fixed-table td {
  padding: 0px 2px; /* Adjust padding as needed */
  vertical-align: middle;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis for long text */
}

.fixed-table tr,
.fixed-table th,
.fixed-table td {
  line-height: 1.0; /* Adjust the line-height value to decrease row height */
}

.single-card{
  border: 2px black solid;
}

th {
  text-align: left;
  /* Center align text horizontally */
  padding: 8px;
  background-color: var(--lighter-primary-color);
  /* Default background color for table heading */
  white-space: nowrap;
  /* Prevent line breaks in table headings */
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-contrast-color);
  /* Add ellipsis (...) for long headings */
}

.instruction-text{
  display: flex;
  justify-content: center;
  min-height: 100px;
  align-items: center;
  background-color: aliceblue;
}

.division-view{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: aliceblue;
}
</style>
